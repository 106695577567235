// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".slide_28WOY{margin:35px 0}@media only screen and (min-width:950px){.slide_28WOY{display:grid;grid-template-columns:Min(25vw,330px) 1fr;grid-gap:50px;gap:50px;margin:35px 25px;padding-bottom:35px;border-bottom:1px solid #b5b5b4}}.buttonWrap_2GPKQ,.imgSection_3c4OE,.text_3cJmZ,.title_1mkm8{padding:0 20px}@media only screen and (min-width:600px){.buttonWrap_2GPKQ,.imgSection_3c4OE,.text_3cJmZ,.title_1mkm8{padding:0 25px}}@media only screen and (min-width:950px){.buttonWrap_2GPKQ,.imgSection_3c4OE,.text_3cJmZ,.title_1mkm8{padding:0}}.imgSection_3c4OE{margin-bottom:25px}@media only screen and (min-width:950px){.imgSection_3c4OE{margin-bottom:0;padding-left:0}}.imgWrap_3dMTw{display:block;position:relative;overflow:hidden;width:100%;padding-top:62%;font-size:0;border-radius:8px;border:1px solid rgba(0,0,0,.1)}@media only screen and (min-width:950px){.imgWrap_3dMTw{height:80%}}@media only screen and (min-width:1150px){.imgWrap_3dMTw{height:100%}}.imgWrap_3dMTw:hover{-webkit-filter:brightness(90%);filter:brightness(90%)}.img_1VE-p{position:absolute;top:0;left:0;width:100%;height:100%;-o-object-fit:cover;object-fit:cover;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.text_3cJmZ{margin-top:15px;margin-bottom:20px;color:#242b59}.buttonWrap_2GPKQ{margin-top:25px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"slide": "slide_28WOY",
	"buttonWrap": "buttonWrap_2GPKQ",
	"imgSection": "imgSection_3c4OE",
	"text": "text_3cJmZ",
	"title": "title_1mkm8",
	"imgWrap": "imgWrap_3dMTw",
	"img": "img_1VE-p"
};
module.exports = ___CSS_LOADER_EXPORT___;
